import { z } from 'zod';

import { baseOrganizationEntitySchema } from './organizationContract';
import {
  Agent,
  ChatModel,
  ResponseLengthPreset,
  TonePreset,
  ChunkTransformerName,
  EFitnessProvider,
} from '../enums';

export const chatThemeSchema = z
  .object({
    primary: z.string().nullish(),
    primaryFg: z.string().nullish(),
    secondary: z.string().nullish(),
    secondaryFg: z.string().nullish(),
    backgroundSecondary: z.string().nullish(),
    background: z.string().nullish(),
    foreground: z.string().nullish(),
    placeholder: z.string().nullish(),
    logo: z.string().nullish(),
    logoBubble: z.string().nullish(),
  })
  .strict();

export type ChatThemeConfig = z.infer<typeof chatThemeSchema>;

export const agentConfigSchema = z
  .object({
    systemPrompt: z.string().optional(),
    companyName: z.string(),
    companyDescription: z.string(),
    capabilities: z.string().optional(),
    hybridAlpha: z.number().nonnegative().optional(),
    smallModel: z.nativeEnum(ChatModel).optional(),
    rewriteRetries: z.number().int().nonnegative().optional(),
    historyLimit: z.number().int().nonnegative().optional(),
    retrievalCount: z.number().int().nonnegative().optional(),
    contextHistoryLimit: z.number().int().nonnegative().optional(),
    rerankCount: z.number().int().nonnegative().optional(),
    rerankWeight: z.number().nonnegative().optional(),
    rerank: z.boolean().optional(),
    imageSupport: z.boolean().optional(),
    showSources: z.boolean().optional(),
    minScore: z.number().optional(),
    tonePreset: z.nativeEnum(TonePreset).optional(),
    responseLengthPreset: z.nativeEnum(ResponseLengthPreset).optional(),
    hasWebSearch: z.boolean().optional(),
    webSearchUrls: z.array(z.string()).optional(),
    chunkTransformers: z.array(z.nativeEnum(ChunkTransformerName)).optional(),
    showFollowUpQuestions: z.boolean().optional(),
    language: z.string().optional(),
  })
  .strict();

export type AgentConfig = z.infer<typeof agentConfigSchema>;

export const chatbotConfigSchema = z
  .object({
    systemName: z.string(),
    systemAvatar: z.string().optional(),
    userName: z.string().optional(),
    maxMessages: z.number().int().nonnegative().optional(),
    maxCharacters: z.number().int().nonnegative().optional(),
    initialMessage: z.string().optional(),
    bubbleTitle: z.string().optional(),
    bubbleDescription: z.string().optional(),
    efitnessProvider: z.nativeEnum(EFitnessProvider),
    disclaimerContact: z.string().email().optional(),
  })
  .strict();

export type ChatbotConfig = z.infer<typeof chatbotConfigSchema>;

export const chatSchema = baseOrganizationEntitySchema.extend({
  name: z.string(),
  agent: z.nativeEnum(Agent).optional(),
  themeConfig: chatThemeSchema.optional(),
  chatbotConfig: chatbotConfigSchema,
  agentConfig: agentConfigSchema,
  model: z.nativeEnum(ChatModel).optional(),
  embeddingsVersion: z.number().int().nonnegative().optional(),
});

export const createChatSchema = chatSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    organization: true,
  })
  .strict();

export const updateChatSchema = chatSchema
  .partial()
  .required({
    id: true,
  })
  .omit({
    organization: true,
  })
  .strict();

export type ChatDTO = z.infer<typeof chatSchema>;
export type CreateChatDTO = z.infer<typeof createChatSchema>;
export type UpdateChatDTO = z.infer<typeof updateChatSchema>;

// Streamed chunk types
export const streamInitChunkSchema = z.object({
  type: z.literal('init'),
  id: z.string(),
});

export const streamTextChunkSchema = z.object({
  type: z.literal('text'),
  content: z.string(),
});

export const streamToolChunkSchema = z.object({
  type: z.literal('tool'),
  id: z.string(),
  index: z.number().int().nonnegative(),
  name: z.string(),
  args: z.string(),
});

export const streamChunkSchema = z.union([
  streamInitChunkSchema,
  streamTextChunkSchema,
  streamToolChunkSchema,
]);

export type StreamInitChunk = z.infer<typeof streamInitChunkSchema>;
export type StreamTextChunk = z.infer<typeof streamTextChunkSchema>;
export type StreamToolChunk = z.infer<typeof streamToolChunkSchema>;
export type StreamChunk = z.infer<typeof streamChunkSchema>;
