import { EFitnessProvider } from '@kanbu/schema/enums';

export type EFitnessClub = {
  id: number;
  name: string;
  displayName: string;
  city: string;
  companyName: string;
};

export const eFitnessClubs: Record<EFitnessProvider, EFitnessClub[]> =
  Object.freeze({
    [EFitnessProvider.Czechia]: [
      {
        id: 1912,
        name: 'Form Factory Lužánky',
        displayName: 'Lužánky',
        city: 'Brno',
        companyName: 'Form Factory s.r.o.',
      },
      {
        id: 2235,
        name: 'Form Factory Průvan',
        displayName: 'Průvan',
        city: 'Brno',
        companyName: 'Form Factory s.r.o.',
      },
      {
        id: 1911,
        name: 'Form Factory Vlněna',
        displayName: 'Vlněna',
        city: 'Brno',
        companyName: 'Form Factory s.r.o.',
      },
      {
        id: 1804,
        name: 'Form Factory Ostrava Avion',
        displayName: 'Avion',
        city: 'Ostrava',
        companyName: 'Form Factory s.r.o.',
      },
      {
        id: 895,
        name: 'Form Factory Anděl',
        displayName: 'Anděl',
        city: 'Praha',
        companyName: 'Form Factory s.r.o.',
      },
      {
        id: 2238,
        name: 'Form Factory Brumlovka',
        displayName: 'Brumlovka',
        city: 'Praha',
        companyName: 'Form Factory s.r.o.',
      },
      {
        id: 1185,
        name: 'Form Factory Butovice',
        displayName: 'Butovice',
        city: 'Praha',
        companyName: 'Form Factory s.r.o.',
      },
      {
        id: 2237,
        name: 'Form Factory Černý Most',
        displayName: 'Černý Most',
        city: 'Praha',
        companyName: 'Form Factory s.r.o.',
      },
      {
        id: 1184,
        name: 'Form Factory Eden',
        displayName: 'Eden',
        city: 'Praha',
        companyName: 'Form Factory s.r.o.',
      },
      {
        id: 1186,
        name: 'Form Factory Harfa',
        displayName: 'Harfa',
        city: 'Praha',
        companyName: 'Form Factory s.r.o.',
      },
      {
        id: 1048,
        name: 'Form Factory Hostivař',
        displayName: 'Hostivař',
        city: 'Praha',
        companyName: 'Form Factory s.r.o.',
      },
      {
        id: 2240,
        name: 'Form Factory I.P.Pavlova',
        displayName: 'I.P.Pavlova',
        city: 'Praha',
        companyName: 'Form Factory s.r.o.',
      },
      {
        id: 896,
        name: 'Form Factory Karlín',
        displayName: 'Karlín',
        city: 'Praha',
        companyName: 'Form Factory s.r.o.',
      },
      {
        id: 2087,
        name: 'Form Factory Letňany',
        displayName: 'Letňany',
        city: 'Praha',
        companyName: 'Form Factory s.r.o.',
      },
      {
        id: 2479,
        name: 'Form Factory Malešice',
        displayName: 'Malešice',
        city: 'Praha',
        companyName: 'Form Factory s.r.o.',
      },
      {
        id: 1187,
        name: 'Form Factory Palladium',
        displayName: 'Palladium',
        city: 'Praha',
        companyName: 'Form Factory s.r.o.',
      },
      {
        id: 1760,
        name: 'Form Factory Pankrác',
        displayName: 'Pankrác',
        city: 'Praha',
        companyName: 'Form Factory s.r.o.',
      },
      {
        id: 2086,
        name: 'Form Factory SO-HO',
        displayName: 'SO-HO',
        city: 'Praha',
        companyName: 'Form Factory s.r.o.',
      },
      {
        id: 1042,
        name: 'Form Factory Stodůlky',
        displayName: 'Stodůlky',
        city: 'Praha',
        companyName: 'Form Factory s.r.o.',
      },
      {
        id: 2334,
        name: 'Form Factory Štúrova',
        displayName: 'Štúrova',
        city: 'Praha',
        companyName: 'Form Factory s.r.o.',
      },
      {
        id: 1041,
        name: 'Form Factory Václavské Náměstí',
        displayName: 'Václavské Náměstí',
        city: 'Praha',
        companyName: 'Form Factory s.r.o.',
      },
      {
        id: 1043,
        name: 'Form Factory Vinohradská',
        displayName: 'Vinohradská',
        city: 'Praha',
        companyName: 'Form Factory s.r.o.',
      },
      {
        id: 1047,
        name: 'Form Factory Vršovická',
        displayName: 'Vršovická',
        city: 'Praha',
        companyName: 'Form Factory s.r.o.',
      },
    ],
    [EFitnessProvider.Slovakia]: [
      {
        id: 2323,
        name: 'Form Factory BBC 5',
        displayName: 'BBC 5',
        city: 'Bratislava',
        companyName: ' Form Factory Slovakia s.r.o',
      },
      {
        id: 1744,
        name: 'Form Factory Nivy',
        displayName: 'Nivy',
        city: 'Bratislava',
        companyName: ' Form Factory Slovakia s.r.o',
      },
      {
        id: 2317,
        name: 'Form Factory Sport House',
        displayName: 'Sport House',
        city: 'Bratislava',
        companyName: ' Form Factory Slovakia s.r.o',
      },
    ],
    [EFitnessProvider.Croatia]: [
      {
        id: 2422,
        name: 'The Fitness Hala',
        displayName: 'Hala',
        city: 'Zagreb',
        companyName: 'Fit Invest d.o.o.',
      },
      {
        id: 2232,
        name: 'THE Fitness & Squash Zavrtnica',
        displayName: 'Zavrtnica',
        city: 'Zagreb',
        companyName: 'Fit Invest d.o.o.',
      },
      {
        id: 2207,
        name: 'THE Fitness Branimir Mingle Mall',
        displayName: 'Branimir',
        city: 'Zagreb',
        companyName: 'H.O.L.S. d.o.o.',
      },
      {
        id: 2206,
        name: 'THE Fitness Green Gold Center',
        displayName: 'Green Gold',
        city: 'Zagreb',
        companyName: 'H.O.L.S. d.o.o.',
      },
      {
        id: 2126,
        name: 'THE Fitness HoB',
        displayName: 'HoB',
        city: 'Zagreb',
        companyName: 'Fit Invest d.o.o.',
      },
      {
        id: 2208,
        name: 'THE Fitness Kaptol Center',
        displayName: 'Kaptol',
        city: 'Zagreb',
        companyName: 'H.O.L.S. d.o.o.',
      },
      {
        id: 2421,
        name: 'The Fitness Mamutica',
        displayName: 'Mamutica',
        city: 'Zagreb',
        companyName: 'Fit Invest d.o.o.',
      },
      {
        id: 2209,
        name: 'THE Fitness Zonar Hotel',
        displayName: 'Zonar',
        city: 'Zagreb',
        companyName: 'H.O.L.S. d.o.o.',
      },
    ],
  });
