import { z } from 'zod';

import { ContentType } from '../enums';

export const ToolNames = {
  RagResult: 'rag_result',
  Membership: 'membership',
} as const;

export const ragResultToolDef = z.object({
  content: z
    .string()
    .describe(
      'Main response with factual information from documents, formatted in markdown',
    ),
  sources: z
    .array(
      z.object({
        type: z.nativeEnum(ContentType).describe('Source document type'),
        url: z.string().describe('Document URL from metadata'),
        fileName: z
          .string()
          .describe(
            'Prettified source document file name from metadata. Remove underscores and capitalize. Drop file extensions.',
          ),
        page: z
          .string()
          .describe(
            'Number of page in the document on which the information was found. The page number is stored in the metadata.',
          ),
      }),
    )
    .optional()
    .describe('List of source documents referenced in the response'),
});

export type RagResultToolDef = z.infer<typeof ragResultToolDef>;

/**
 * Tool definition for eFitness membership related questions.
 * We define multiple output structures, which we use
 * based on the LLM response type.
 */
export const membershipToolDef = z.object({
  type: z
    .enum([
      'membership_status',
      'user_profile',
      'membership_registration',
      'membership_login',
      'other',
    ])
    .describe('Type of response based on user query intent'),

  // Tool for showing current membership status
  membership_status: z
    .object({
      type: z.literal('membership_status'),
      content: z
        .string()
        .min(1)
        .describe(
          'Natural conversational response about membership status. Format using markdown when appropriate.',
        ),
      activeMembership: z
        .string()
        .describe(
          'Name of the active membership plan. Should be exactly as stored in the system.',
        ),
      activeFrom: z
        .string()
        .describe('Membership start date in ISO format (YYYY-MM-DD).'),
      activeTo: z
        .string()
        .describe('Membership end date in ISO format (YYYY-MM-DD).'),
      isExpired: z.boolean().describe('Whether the membership is expired'),
      showNewMembershipButton: z
        .boolean()
        .describe(
          'Whether to show new membership button. Show when: 1) User explicitly asks about new membership, 2) Current membership is expired, 3) User asks about extending membership.',
        ),
    })
    .optional()
    .describe('Current membership status, when type is membership_status'),

  // Tool for showing user profile information
  user_profile: z
    .object({
      type: z.literal('user_profile'),
      content: z
        .string()
        .describe(
          'Natural conversational response about user profile. Format using markdown when appropriate.',
        ),
      firstName: z.string().describe('User first name'),
      lastName: z.string().describe('User last name'),
      email: z.string().describe('User email'),
      phone: z.string().optional().describe('User phone number'),
      gender: z
        .enum(['man', 'woman', 'other'])
        .optional()
        .describe('User gender'),
      birthDate: z.string().optional().describe('User birth date'),
      address: z.string().optional().describe('User address'),
      isEmailVerified: z.boolean().describe('Whether the email is verified'),
    })
    .optional()
    .describe('User profile information, when type is user_profile'),

  // Tool for membership registration prompt
  membership_registration: z
    .object({
      type: z.literal('membership_registration'),
      content: z
        .string()
        .describe(
          'Natural conversational response about membership registration. Format using markdown when appropriate.',
        ),
      shouldRegister: z
        .boolean()
        .describe('Whether the user should be prompted to register'),
    })
    .optional()
    .describe(
      'Membership registration prompt, when type is membership_registration',
    ),

  // Tool for login prompt
  membership_login: z
    .object({
      type: z.literal('membership_login'),
      content: z
        .string()
        .describe(
          'Natural conversational response about login requirement. Format using markdown when appropriate.',
        ),
      shouldLogin: z.boolean().describe('Whether the user should login'),
    })
    .optional()
    .describe('Membership login prompt, when type is membership_login'),

  // Tool for other topics
  other: z
    .object({
      type: z.literal('other'),
      content: z
        .string()
        .describe(
          'Natural conversational response about other topics. Format using markdown when appropriate.',
        ),
    })
    .optional(),
});

export type MembershipToolDef = z.infer<typeof membershipToolDef>;
