import { ChatRole } from '@kanbu/schema/enums';
import { ErrorCodes } from '@kanbu/shared';
import { useQuery } from '@tanstack/react-query';
import { cn } from '@utima/ui';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v7 as uuidV7 } from 'uuid';

import { AsistentMessage } from '@/components/message/AsistentMessage';
import { UserMessage } from '@/components/message/UserMessage';
import { useChatConfig } from '@/contexts/ChatConfigProvider';
import type { UseChatParams } from '@/hooks/chatTypes';
import { createMessage, useChat } from '@/hooks/useChat';
import { useDelayedLoading } from '@/hooks/useDelayedLoading';
import { useProvider } from '@/hooks/useProvider';
import { hasPaymentParam, removePaymentParam } from '@/lib/utils';
import { aiCoreApi } from '@/services/aiCoreClient';
import { threadKeys } from '@/services/queryClient';
import { useBoundStore } from '@/store/store';

import { FAQs } from '../faqs/FAQs';
import { InputBox } from '../inputBox/InputBox';

export function Chat() {
  const { t } = useTranslation();
  const { chatId, model, embeddingsVersion, chatbotConfig } = useChatConfig();
  const { companyName } = useProvider();
  const [faqIndex, setFaqIndex] = useState<number | null>(null);
  const [
    threadId,
    setThreadId,
    opened,
    setUnread,
    childrenCount,
    isInFAQLoop,
    user,
    currentFAQId,
  ] = useBoundStore(state => [
    state.threadId,
    state.setThreadId,
    state.opened,
    state.setUnread,
    state.childrenCount,
    state.isInFAQLoop,
    state.user,
    state.currentFAQId,
  ]);

  /**
   * Fetch user threads to display in the chat list.
   */
  useQuery({
    queryKey: [...threadKeys.chatLists(chatId), user?.id],
    staleTime: Number.POSITIVE_INFINITY,
    queryFn: async () => {
      try {
        const data = await aiCoreApi.threads.findAll(
          {
            chatId,
          },
          /**
           * We don't want to throw an error if the thread is not found
           * This will allow us to create a new thread.
           */
          {
            throwHttpErrors: false,
            retry: 0,
          },
        );

        // Set thread id to the first thread in the list
        setThreadId(data[0].id);

        return data;
      } catch {
        // Initialize new thread
        setThreadId(uuidV7());

        return [];
      }
    },
    enabled: opened,
  });

  /**
   * Always set unread, this should be cleaned up when clicking close button.
   */
  const handleMessage = useCallback(() => {
    setUnread(1);
  }, [setUnread]);

  /**
   * Error handler for the chat.
   */
  const handleError = useCallback<Required<UseChatParams>['onError']>(
    async (error, insert) => {
      if (typeof error === 'object' && error !== null && 'errorCode' in error) {
        const { errorCode } = error;

        // Insert a message to the chat to inform the user about the error
        if (errorCode === ErrorCodes.USAGE_LIMITS_EXCEEDED) {
          return insert(t('errors.usageLimitsExceeded'), ChatRole.Assistant);
        }
      }

      // Handle all other errors
      return insert(
        createMessage({
          message: t('errors.unavailableChat'),
          role: ChatRole.Assistant,
        }),
      );
    },
    [t],
  );

  /**
   * This handles the chat logic, it fetches the messages from the API
   * and sends new messages to the API.
   */
  const {
    input,
    handleInputChange,
    messages,
    handleSubmit,
    isLoading,
    isStreaming,
    isInitialized,
    abort,
    insert,
  } = useChat({
    threadId,
    chatId,
    model,
    embeddingsVersion,
    initialMessages: [
      createMessage({
        message: t('texts.initialMessage', { companyName }),
        role: ChatRole.Assistant,
      }),
    ],
    onError: handleError,
    onMessage: handleMessage,
  });

  // Payment logic
  useEffect(() => {
    if (!isInitialized) {
      return;
    }

    if (hasPaymentParam()) {
      removePaymentParam();

      insert(
        createMessage({
          message: t('messages.paymentSuccess'),
          role: ChatRole.Assistant,
        }),
      );
    }
  }, [isInitialized, insert, t]);

  /**
   * Following two useEffects are used to reset the faqIndex when the threadId changes
   * or when the messages change. This is used to always make sure we show the FAQs
   * after the last AI message upon conversation reset or page reload.
   */
  useEffect(() => {
    setFaqIndex(null);
  }, [threadId]);

  useEffect(() => {
    if (messages && messages.length > 0) {
      const lastAiMessageIndex = messages.findLastIndex(
        msg => msg.role === ChatRole.Assistant,
      );

      if (
        faqIndex === null ||
        (childrenCount && childrenCount > 0 && isInFAQLoop) ||
        (currentFAQId && currentFAQId !== '')
      ) {
        setFaqIndex(lastAiMessageIndex === -1 ? 0 : lastAiMessageIndex);
      }
    }
  }, [messages, faqIndex, childrenCount, isInFAQLoop, currentFAQId]);

  const throttledIsLoading = useDelayedLoading(isLoading, 500);

  return (
    <form className='relative flex h-full flex-col' onSubmit={handleSubmit}>
      <div className='flex grow flex-col-reverse overflow-y-auto px-5 py-4'>
        <div className='flex grow flex-col gap-6 [&>*:first-child]:mt-auto'>
          <AnimatePresence mode='popLayout'>
            {messages.map((message, index) => {
              return (
                <motion.div
                  key={message.id}
                  initial={{ y: 40, opacity: 0 }}
                  animate={{ opacity: 1, y: 0 }}
                >
                  {message.role === ChatRole.User ? (
                    <UserMessage data={message} />
                  ) : (
                    <AsistentMessage data={message} />
                  )}
                  {isInFAQLoop && index === faqIndex && !isLoading && (
                    <motion.div
                      key='faqs'
                      initial={{ y: 40, opacity: 0 }}
                      animate={{ opacity: 1, y: 0 }}
                    >
                      <FAQs insert={insert} />
                    </motion.div>
                  )}
                </motion.div>
              );
            })}
            {throttledIsLoading && (
              <motion.div
                key='loader'
                initial={{ y: 40, opacity: 0 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <AsistentMessage typing />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <div className={cn('shrink-0 bg-background-secondary px-5 py-4')}>
        <InputBox
          value={input}
          onChange={handleInputChange}
          onAbort={abort}
          streaming={isStreaming}
          loading={isLoading}
          initializing={!isInitialized}
          maxLength={chatbotConfig?.maxCharacters}
        />
      </div>
    </form>
  );
}
