export enum Rating {
  Neutral = 'neutral',
  Positive = 'positive',
  Negative = 'negative',
}

export enum ChatRole {
  User = 'user',
  Assistant = 'assistant',
}

export enum Role {
  SuperAdmin = 'superAdmin',
  Admin = 'admin',
}

export enum RoleWOSuperAdmin {
  Admin = 'admin',
}

export enum Agent {
  JAMU = 'jamu',
  ConversationalRag = 'conversationalRag',
  FormFactory = 'formFactory',
  ConversationalRagV2 = 'conversationalRagV2',
}

/**
 * OpenAI: https://platform.openai.com/docs/models/gpt-4o
 * Anthropic: https://docs.anthropic.com/en/docs/about-claude/models#model-comparison-table
 * openrouter.ai: https://openrouter.ai/
 */
export enum ChatModel {
  // Anthropic models
  Claude3Opus = 'claude-3-opus-20240229',
  Claude35Sonnet20241022 = 'claude-3-5-sonnet-20241022',
  Claude35Sonnet20240620 = 'claude-3-5-sonnet-20240620',
  Claude35Sonnet = 'claude-3-5-sonnet-latest',
  Claude3Haiku = 'claude-3-haiku-20240307',

  // OpenAI models
  Gpt4o_2024_08_06 = 'gpt-4o-2024-08-06',
  Gpt4o_2024_11_20 = 'gpt-4o-2024-11-20',
  Gpt4o = 'gpt-4o',
  Gpt4oMini = 'gpt-4o-mini',
  GptO1 = 'gpt-o1',
  GptO1_Mini = 'gpt-o1-mini',

  // OpenRouter.ai models
  DeepSeekV3 = 'deepseek/deepseek-chat',
}

export enum ContentType {
  Website = 'website',
  Document = 'document',
  ExternalDocument = 'externalDocument',
}

export enum UsageRecordOperation {
  Chat = 'chat',
  Embedding = 'embedding',
  Rerank = 'rerank',
}

export enum ToolType {
  Text = 'text',
  Tool = 'tool',
}

export enum TonePreset {
  Friendly = 'friendly',
  Casual = 'casual',
  Balanced = 'balanced',
  Professional = 'professional',
  Formal = 'formal',
}

export enum ResponseLengthPreset {
  Concise = 'concise', // Ultra short, just facts
  Balanced = 'balanced', // Normal conversational length
  Detailed = 'detailed', // Comprehensive with examples
}

export enum Language {
  Czech = 'cs',
  English = 'en',
  Slovak = 'sk',
  Croatian = 'hr',
}

export enum EFitnessGender {
  WOMAN,
  MAN,
  OTHER,
}

export * from './eFitnessEnums';

export enum DocumentStatus {
  ParsingError = 'parsingError',
  EmbeddingError = 'embeddingError',
  Uploading = 'uploading',
  Ready = 'ready',
  Parsing = 'parsing',
  ParsingAsync = 'parsingAsync', // Represents a job that is scheduled but not yet completed
  Parsed = 'parsed',
  Embedding = 'embedding',
  Done = 'done',
}

export enum JobProvider {
  LlamaParse = 'llamaparse',
  Firecrawl = 'firecrawl',
}

export enum ChunkTransformerName {
  Contextualized = 'contextualized',
  Jamu = 'jamu',
}

export enum CapabilitiesPreset {
  CustomerSupport = 'Zodpovídání dotazů o společnosti, jejich produktech a službách za účelem poskytnout uživateli dostatek informací k vyřešení problému',
  Sales = 'Zodpovídání dotazů o společnosti, jejich produktech a službách za účelem prodeje',
  HR = 'Zodpovídání dotazů o společnosti, jejich produktech a službách za účelem péče o uživatele',
}

export const themePresets = {
  light: {
    primary: '#388BFD',
    primaryFg: '#ffffff',

    secondary: '#66BB6A',
    secondaryFg: '#ffffff',

    background: '#ffffff',
    backgroundSecondary: '#f2f2f2',

    foreground: '#333333',
    placeholder: '#aaaaaa',
  },
  dark: {
    primary: '#5A7BFD',
    primaryFg: '#FFFFFF',

    secondary: '#66BB6A',
    secondaryFg: '#FFFFFF',

    background: '#121212',
    backgroundSecondary: '#1F1F1F',

    foreground: '#EAEAEA',
    placeholder: '#AAAAAA',
  },
  kanbu: {
    primary: '#FFCF52',
    primaryFg: '#242424',

    secondary: '#8A2BE2',
    secondaryFg: '#fff',

    background: '#fff',
    backgroundSecondary: '#f0f0f0',

    foreground: '#070606',
    placeholder: '#898989',
  },
} as const;
