import { formatUtils } from '@kanbu/shared';
import { Avatar, Separator } from '@utima/ui';
import { Minus } from 'lucide-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useProvider } from '@/hooks/useProvider';
import { useBoundStore } from '@/store/store';

import { Image } from '../image/Image';
import { ResetConversation } from '../resetConversation/ResetConversation';
import { SignInButton } from '../signIn/SignInButton';

export const TopBar = memo(function TopBar() {
  const { t } = useTranslation();
  const { logo } = useProvider();
  const [user, close, setUnread, pushRoute, logout] = useBoundStore(state => [
    state.user,
    state.close,
    state.setUnread,
    state.pushRoute,
    state.logout,
  ]);

  /**
   * Close and clear unread messages.
   */
  const handleClose = () => {
    setUnread(0);
    close();
  };

  return (
    <div className='sticky top-0 flex h-14 flex-row items-center justify-between bg-primary px-4'>
      <div className='flex items-center gap-3'>
        <Image className='h-5' src={logo} />
      </div>
      <div className='flex gap-4'>
        {user?.externalId ? (
          <button
            type='button'
            className='flex items-center gap-2 text-sm text-secondary-fg transition-all enabled:hover:scale-105 enabled:hover:opacity-70 enabled:active:scale-100 disabled:cursor-not-allowed disabled:opacity-75'
            onClick={() => logout()}
          >
            {t('topBar.logout')}
            <Avatar
              size='xs'
              fallback={formatUtils.initials(formatUtils.fullName(user))}
            />
          </button>
        ) : (
          <SignInButton onSubmit={() => pushRoute('signIn')} />
        )}

        <Separator orientation='vertical' className='h-6 opacity-30' />
        {/* FIXME MISSING ON RESET */}
        <ResetConversation />
        <button
          type='button'
          className='flex items-center justify-center text-primary-fg transition-transform hover:scale-110 active:scale-100'
          onClick={handleClose}
        >
          <Minus className='size-6' />
        </button>
      </div>
    </div>
  );
});
