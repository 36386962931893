import { useMutation, useQuery } from '@tanstack/react-query';
import { Button } from '@utima/ui';
import { Form, type TypedFormState, Input } from '@utima/ui-informed';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useEfErrorHandler } from '@/hooks/useEfErrorHandler';
import { aiCoreApi } from '@/services/aiCoreClient';
import { membershipDefinitionsKeys } from '@/services/queryClient';
import { useBoundStore } from '@/store/store';

import { EfErrorAlert } from '../alerts/EfErrorAlert';
import { ClubSelect } from '../eFitness/ClubSelect';
import { NativeFormSelect } from '../nativeFormSelect/NativeFormSelect';
import { Stack } from '../stack/Stack';

type FormValues = {
  club: string;
  membership: string;
  date: string;
  discountCoupon?: string;
};

enum PeriodType {
  Day = 0,
  Week = 1,
  Month = 2,
  All = 3,
  FourWeeks = 4,
  TwoWeeks = 5,
  ThreeMonths = 6,
  Settlement = 7,
}

const translationsPeriod: {
  [key: string]: {
    [key in PeriodType]: {
      singular: string;
      plural: string;
    };
  };
} = {
  en: {
    [PeriodType.Day]: { singular: 'Day', plural: 'Days' },
    [PeriodType.Week]: { singular: 'Week', plural: 'Weeks' },
    [PeriodType.Month]: { singular: 'Month', plural: 'Months' },
    [PeriodType.All]: { singular: 'All', plural: 'All' },
    [PeriodType.FourWeeks]: { singular: 'Four Weeks', plural: 'Four Weeks' },
    [PeriodType.TwoWeeks]: { singular: 'Two Weeks', plural: 'Two Weeks' },
    [PeriodType.ThreeMonths]: {
      singular: 'Three Months',
      plural: 'Three Months',
    },
    [PeriodType.Settlement]: { singular: 'Settlement', plural: 'Settlements' },
  },
  cz: {
    [PeriodType.Day]: { singular: 'Den', plural: 'Dnů' },
    [PeriodType.Week]: { singular: 'Týden', plural: 'Týdnů' },
    [PeriodType.Month]: { singular: 'Měsíc', plural: 'Měsíců' },
    [PeriodType.All]: { singular: 'Všechno', plural: 'Všechno' },
    [PeriodType.FourWeeks]: { singular: 'Čtyři týdny', plural: 'Čtyři týdny' },
    [PeriodType.TwoWeeks]: { singular: 'Dva týdny', plural: 'Dva týdny' },
    [PeriodType.ThreeMonths]: { singular: 'Tři měsíce', plural: 'Tři měsíce' },
    [PeriodType.Settlement]: { singular: 'Vyrovnání', plural: 'Vyrovnání' },
  },
};

export function getPeriodTypeName(
  periodId: number | undefined,
  t: any,
  count: number | undefined,
  language: string,
): string {
  const langTranslationsPeriod = translationsPeriod[language];
  if (periodId === null || periodId === undefined) {
    return t('membershipRegistration.undefineContract');
  }
  const translation = langTranslationsPeriod[periodId as PeriodType];

  return count === 1 ? translation.singular : translation.plural;
}

export function NewMembership() {
  const { t } = useTranslation();
  const [clubId, setClubId] = useState<string | undefined>(undefined);
  const { handleError, resetErrors, errors } = useEfErrorHandler();
  const [membershipDetails, setMembershipDetails] = useState<{
    price: number | undefined;
    description: string | undefined;
    periodType: number | undefined;
    periodTime: number | undefined;
    installmentsNumber: number | undefined;
    name: string | undefined;
  }>({
    price: undefined,
    description: undefined,
    periodType: undefined,
    periodTime: undefined,
    installmentsNumber: undefined,
    name: undefined,
  });

  const descriptionItems = membershipDetails.description
    ? membershipDetails.description.split('||')
    : [];

  const [isMembershipSelected, setIsMembershipSelected] = useState(false);
  const [setRegistrationMemberData] = useBoundStore(state => [
    state.setRegistrationMemberData,
  ]);

  const { pushRoute } = useBoundStore(state => ({
    pushRoute: state.pushRoute,
    popRoute: state.popRoute,
  }));

  const { data, refetch } = useQuery({
    queryKey: membershipDefinitionsKeys.memberships(),
    queryFn: async () => {
      if (!clubId) {
        return [];
      }

      return aiCoreApi.eFitness.membershipDefinitions.memberships({
        clubId,
      });
    },
    retry: 0,
    enabled: false,
  });

  useEffect(() => {
    if (clubId) {
      refetch();
    }
  }, [clubId, refetch]);

  const { mutateAsync } = useMutation({
    mutationFn: async (formState: TypedFormState<FormValues>) => {
      const { club, membership, date, discountCoupon } = formState.values;

      /**
       * This is complete bullshit, we already have the types in eFitnessContracts
       * but here we have `any`. We have to fix this in the future since type safety
       * is gone. Skipping for now due to time constraints.
       */
      setRegistrationMemberData({
        clubId: club,
        membershipId: Number(membership),
        date: new Date(date).toISOString(),
        price: membershipDetails.price,
        description: membershipDetails.description,
        periodType: membershipDetails.periodType,
        periodTime: membershipDetails.periodTime,
        installmentsNumber: membershipDetails.installmentsNumber,
        name: membershipDetails.name,
        discountCoupon: discountCoupon,
      });

      pushRoute('newMembershipConsent');
    },
  });

  const handleSubmit = async (formState: TypedFormState<FormValues>) => {
    resetErrors();

    try {
      await mutateAsync(formState);
    } catch (err) {
      await handleError(err);
    }
  };

  return (
    <Stack
      title={t('membershipRegistration.newMembership')}
      onBack={() => pushRoute('chat')}
    >
      <EfErrorAlert errors={errors} />
      <Form onSubmit={handleSubmit} className='flex flex-col gap-5'>
        <ClubSelect
          placeholder={t('membershipRegistration.chooseClub')}
          required
          name='club'
          label={t('membershipForm.club')}
          onChange={(e: any) => {
            if (e.value === '') return;
            setClubId(e.value);
          }}
        />
        <NativeFormSelect
          defaultValue=''
          placeholder={t('membershipRegistration.chooseMembershipType')}
          required
          name='membership'
          label={t('membershipRegistration.membershipType')}
          disabled={!clubId}
          onChange={(e: any) => {
            const selectedDefinition = data?.find(
              definition => definition.id.toString() === e.value,
            );

            if (selectedDefinition) {
              setMembershipDetails({
                price: selectedDefinition.installmentPrice,
                description: selectedDefinition.internetDescription,
                periodType: selectedDefinition.periodType,
                periodTime: selectedDefinition.periodTime,
                name: selectedDefinition.name,
                installmentsNumber: selectedDefinition.installmentsNumber,
              });
              setIsMembershipSelected(true);
            }
          }}
        >
          {data
            ?.filter(
              definition => definition.id !== 55401 && definition.id !== 52059,
            )
            .map(definition => (
              <option key={definition.id} value={definition.id.toString()}>
                {definition.name}
              </option>
            ))}
        </NativeFormSelect>

        <Input
          name='date'
          required
          label={t('membershipRegistration.membershipStart')}
          type='date'
          min={new Date().toISOString().split('T')[0]}
          zodItemSchema={z.string().refine(
            value => {
              const date = new Date(value);
              date.setHours(23, 59, 59, 999);

              return date.getTime() >= Date.now();
            },
            {
              message: t('validation.invalidDate'),
            },
          )}
        />
        <Input
          name='discountCode'
          label={t('membershipRegistration.discountCode')}
        />
        {isMembershipSelected && (
          <div className='text-white'>
            <span className='font-bold'>
              {t('membershipRegistration.price')}:
            </span>{' '}
            {membershipDetails.price} Kč{' '}
            {membershipDetails.installmentsNumber === 1
              ? ''
              : membershipDetails.installmentsNumber === 2 ||
                  membershipDetails.installmentsNumber === 12
                ? '/ ' + t('membershipRegistration.monthly')
                : ''}
            <br />
            <span className='font-bold'>
              {t('membershipRegistration.description')}:
            </span>
            {membershipDetails.description && (
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                {descriptionItems.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            )}
            <br />
            <span className='font-bold'>
              {t('membershipRegistration.contractPeriod')}:
            </span>{' '}
            {membershipDetails.periodTime}{' '}
            {getPeriodTypeName(
              membershipDetails.periodType,
              t,
              membershipDetails.periodTime,
              'cz',
            )}
          </div>
        )}
        <Button size='lg' type='submit'>
          {t('membershipRegistration.continue')}
        </Button>
      </Form>
    </Stack>
  );
}
