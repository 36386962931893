import type { EFitnessProvider } from '@kanbu/schema/enums';
import { cn } from '@utima/ui';
import type { Select as InformedSelect } from 'informed';
import type { ComponentPropsWithoutRef } from 'react';

import { useChatConfig } from '@/contexts/ChatConfigProvider';

import { eFitnessClubs, type EFitnessClub } from './eFitnessClubs';
import { NativeFormSelect } from '../nativeFormSelect/NativeFormSelect';

export interface ClubSelectProps
  extends Omit<ComponentPropsWithoutRef<typeof InformedSelect>, 'children'> {}

/**
 * Form factory clubs grouped by the provider and city.
 */
const groupedClubs = Object.entries(eFitnessClubs).reduce(
  (acc, current) => {
    const [provider, clubs] = current;
    const groupedClubs = Object.groupBy(clubs, c => c.city) as Record<
      string,
      EFitnessClub[]
    >;

    acc[provider as EFitnessProvider] = groupedClubs;

    return acc;
  },
  {} as Record<EFitnessProvider, Record<string, EFitnessClub[]>>,
);

export function ClubSelect({ className, ...restProps }: ClubSelectProps) {
  const { chatbotConfig } = useChatConfig();
  const { efitnessProvider } = chatbotConfig;
  const clubs = groupedClubs[efitnessProvider];

  return (
    <NativeFormSelect
      className={cn(
        'relative flex h-9 px-3 py-2 w-full min-w-full appearance-none rounded-radius bg-foreground text-sm text-background outline-none transition-all focus:bg-foreground disabled:cursor-not-allowed disabled:opacity-75',
        className,
      )}
      {...restProps}
    >
      {Object.entries(clubs).map(([city, clubs]) => (
        <optgroup key={city} label={city}>
          {clubs.map(club => (
            <option key={club.id} value={club.id.toString()}>
              {club.displayName}
            </option>
          ))}
        </optgroup>
      ))}
    </NativeFormSelect>
  );
}
