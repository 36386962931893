import { EFitnessProvider } from '@kanbu/schema/enums';

import logoImg from '@/assets/images/logo-white.svg';
import theFitnessLogoImg from '@/assets/images/the-fitness-logo-white.svg';
import { useChatConfig } from '@/contexts/ChatConfigProvider';

export function useProvider() {
  const { chatbotConfig } = useChatConfig();

  return {
    companyName:
      chatbotConfig?.efitnessProvider === EFitnessProvider.Croatia
        ? 'The Fitness'
        : 'Form Factory',
    provider: chatbotConfig?.efitnessProvider,
    logo:
      chatbotConfig?.efitnessProvider === EFitnessProvider.Croatia
        ? theFitnessLogoImg
        : logoImg,
  };
}
