import type { ChatUserType } from '@kanbu/schema';
import type { EfCreateMemberDTO } from '@kanbu/schema/contracts';
import { EFitnessGender } from '@kanbu/schema/enums';
import { DateFormat, formatUtils } from '@kanbu/shared';
import { useMutation } from '@tanstack/react-query';
import { Button } from '@utima/ui';
import { Input, type TypedFormState } from '@utima/ui-informed';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import MultipleStepForm from '@/components/multistepForm/MultipleStepForm';
import StepForm from '@/components/multistepForm/StepForm';
import { useEfErrorHandler } from '@/hooks/useEfErrorHandler';
import { aiCoreApi } from '@/services/aiCoreClient';
import { useBoundStore } from '@/store/store';

import { EfErrorAlert } from '../alerts/EfErrorAlert';
import { ClubSelect } from '../eFitness/ClubSelect';
import { NativeFormSelect } from '../nativeFormSelect/NativeFormSelect';
import { Stack } from '../stack/Stack';

type FormValues = EfCreateMemberDTO & { club: string };

export function MemberRegistration() {
  const { t } = useTranslation();
  const { errors, handleError, resetErrors } = useEfErrorHandler();
  const { login, pushRoute, popRoute } = useBoundStore(state => ({
    login: state.login,
    pushRoute: state.pushRoute,
    popRoute: state.popRoute,
  }));

  // TODO: This is here as a workaround to show long error message, since @utima/ui does not support long error messages
  //       as they clip into the inputs. This should be removed once the issue is fixed in the library.
  const [passwordError, setPasswordError] = useState<string | undefined>(
    undefined,
  );

  const validatePassword = (password: string) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialCharacter = /[!"#$%&()*,.:<>?@^{|}]/.test(password);

    if (
      password.length < minLength ||
      !hasUpperCase ||
      !hasNumber ||
      !hasSpecialCharacter
    ) {
      setPasswordError(t('validation.invalidPassword'));

      return false;
    }

    setPasswordError(undefined);
  };

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (formState: TypedFormState<FormValues>) => {
      return aiCoreApi.eFitness.members.create({
        ...formState.values,
        clubId: formState.values.club,
        gender: Number(formState.values.gender),
      });
    },
  });

  const handleSubmit = async (formState: TypedFormState<FormValues>) => {
    resetErrors();

    try {
      const { token, user, eFitness } = await mutateAsync(formState);

      login(user as unknown as ChatUserType, token, eFitness);
      pushRoute('newMembership');
    } catch (err) {
      await handleError(err);
    }
  };

  return (
    <Stack title={t('texts.memberRegistration')} onBack={() => popRoute()}>
      <EfErrorAlert errors={errors} />
      <MultipleStepForm showStepCounter onSubmit={handleSubmit}>
        <StepForm
          zodSchema={z
            .object({
              email: z.string().email(),
              phone: z.string().refine((val: string) => /^\d+$/.test(val), {
                message: t('validation.invalidPhoneFormat'),
              }),
            })
            .strict()}
          validateOn='change-submit'
          className='flex flex-col gap-5'
        >
          <ClubSelect
            defaultValue='895'
            required
            name='club'
            label={t('membershipForm.club')}
          />
          <Input name='firstName' label={t('labels.firstName')} required />
          <Input required name='lastName' label={t('labels.lastName')} />
          <Input name='email' type='email' required label={t('labels.email')} />
          <div className='flex flex-row justify-around gap-2'>
            <NativeFormSelect
              defaultValue='+420'
              required
              className='w-1/4'
              name='countryPhoneCode'
              label={t('labels.mobilePhonePrefix')}
            >
              <option value='+420'>+420</option>
              <option value='+421'>+421</option>
              <option value='+385'>+385</option>
            </NativeFormSelect>
            <Input
              className='w-3/4'
              name='cellPhone'
              maxLength={9}
              zodItemSchema={z
                .string()
                .refine((val: string) => /^\d{9}$/.test(val), {
                  message: t('validation.invalidPhoneFormat'),
                })}
              required
              label={t('labels.mobilePhone')}
            />
          </div>
          <Input
            name='password'
            passwordPreview
            type='password'
            min={8}
            validate={val => validatePassword(String(val))}
            required
            label={t('labels.password')}
          />
          {passwordError && (
            <p className='-bottom-3 -mt-3 text-xs text-danger animate-in fade-in'>
              {passwordError}
            </p>
          )}
          <Button
            size='lg'
            type='submit'
            disabled={isPending}
            loading={isPending}
          >
            {t('actions.nextStep')}
          </Button>
        </StepForm>

        <StepForm className='flex flex-col gap-5'>
          <NativeFormSelect
            name='gender'
            defaultValue=''
            label={t('labels.gender')}
            required
          >
            <option value={EFitnessGender.MAN.toString()}>
              {t('enums.gender.male')}
            </option>
            <option value={EFitnessGender.WOMAN.toString()}>
              {t('enums.gender.female')}
            </option>
            <option value={EFitnessGender.OTHER.toString()}>
              {t('enums.gender.other')}
            </option>
          </NativeFormSelect>
          <Input
            label={t('labels.birthDate')}
            name='birthday'
            type='date'
            required
            max={new Date().toISOString().split('T')[0]}
            zodItemSchema={z.string().refine(
              value => {
                const date = new Date(value);

                return date.getTime() <= Date.now();
              },
              {
                message: t('validation.invalidDate'),
              },
            )}
            /* @ts-expect-error Property 'mask' does not exist on type 'IntrinsicAttributes & InputProps' */
            mask={value => formatUtils.date(value, DateFormat.DateInput)}
          />
          <Input name='street' label={t('labels.street')} required />
          <Input name='city' label={t('labels.city')} required />
          <Input
            name='postalCode'
            maxLength={5}
            zodItemSchema={z
              .string()
              .refine((val: string) => /^\d{5}$/.test(val), {
                message: t('validation.invalidZipCode'),
              })}
            label={t('labels.zipCode')}
            required
          />
          <Button
            size='lg'
            type='submit'
            disabled={isPending}
            loading={isPending}
          >
            {t('loginForm.submit')}
          </Button>
        </StepForm>
      </MultipleStepForm>
    </Stack>
  );
}
